import React, { useState } from 'react';
import { images } from '../../config/galleryConfig';
import './Gallery.css';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

type Props = {};

function Gallery({}: Props) {
  const [galleryImages, setGalleryImages] = useState(images);

  return (
    <div className='gallery-page-wrapper'>
      <section className='gallery-banner'>
        <h2>Gallery</h2>
      </section>

      <ResponsiveMasonry
        style={{ maxWidth: '1200px', margin: '0 auto', padding: '12px 0px' }}
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
      >
        <Masonry gutter='12px'>
          {galleryImages.map((image, i) => (
            <img
              key={image.id}
              src={image.src}
              style={{ width:'100%', display: 'block' }}
              alt={image.alt}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default Gallery;
