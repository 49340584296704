import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactBannerCta.css';

function ContactBannerCta(props: {}) {
  const navigate = useNavigate();

  return (
    <section className='contact-banner-wrapper'>
      <div className='contact-banner-row'>
        <div className='banner-left-col'>
          <p>Contact Us</p>
        </div>
        <div className='banner-right-col'>
          <button className='contact-cta' onClick={()=>navigate('/contact')}>Contact Us</button>
        </div>
      </div>
    </section>
  );
}

export default ContactBannerCta;
