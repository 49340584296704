import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faqsQues } from '../../config/faqsConfig';
import IntoImage from '../../assets/gallery/satisfiedcustomers.jpg';
import Grid1 from '../../assets/gallery/combo.jpg';
import Grid2 from '../../assets/gallery/grapeleaves.jpg';
import Grid3 from '../../assets/gallery/wings.jpg';
import Grid4 from '../../assets/gallery/pita.jpg';
import ContactBannerCta from '../../components/ContactBannerCta/ContactBannerCta';
import './Catering.css';

type Props = {};

function Catering({}: Props) {
  return (
    <div className='catering-page-wrapper'>
      <section className='catering-banner'>
        <h2>Catering</h2>
      </section>

      <section className='intro-wrapper container'>
        <div className='catering-heading'>
          <h3>Catering Services by Layalina</h3>
        </div>
        <div className='services-intro-row'>
          <div className='into-description-col'>
            <h5>Layalina 'caters' to your every need.</h5>
            <p>
              We offer catering services for your private events and corporate
              occasions. Entertain with peace of mind with our made to order,
              fresh and delicious menu offerings. We'll work with you to make
              your event a success with timely order delivery right to your
              event and assistance in the setup process.
            </p>
          </div>
          <div className='catering-image-col'>
            <img
              src={IntoImage}
              alt=''
            />
          </div>
        </div>
      </section>

      <section className='faqs-wrapper container'>
        <div className='faqs-row'>
          <div className='faqs-description-col'>
            <h3>Additional Info</h3>
            <p>
              Looking for a space to host your next event? Look no further than
              our location in the heart of Downton Denton, right off the square.
            </p>
            <div className='questions-wrapper'>
              <h6>Contact us to get your queries answered</h6>
              <ul className='faqs-list'>
                {faqsQues.map((ques, i) => (
                  <li key={`ques-${i}`}>
                    <FontAwesomeIcon
                      style={{ fontSize: '18px', marginRight: '10px' }}
                      icon={faAngleRight}
                    />
                    <span>{ques}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='grid-images-col'>
            <div className='image-grid grid-row-1'>
              <img
                className='grid-image'
                src={Grid1}
                alt=''
              />
              <img
                className='grid-image'
                src={Grid2}
                alt=''
              />
            </div>
            <div className='image-grid grid-row-2'>
              <img
                className='grid-image'
                src={Grid3}
                alt=''
              />
              <img
                className='grid-image'
                src={Grid4}
                alt=''
              />
            </div>
          </div>
        </div>
      </section>

      <ContactBannerCta />
    </div>
  );
}

export default Catering;
