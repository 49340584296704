import Logo from '../../assets/layalina-gold-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faYelp, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import './Footer.css'

type Props = {}

const Footer = (props: Props) => {
    return (
        <footer className='layalina-footer'>
            <div className="section-content">
                <div className="footer-columns font-medium">
                    <div className='footer-col-1'>
                        <img width='220' src={Logo} alt='logo' />
                        <ul className='social-links'>
                            <li className='social-link'>
                                <a target='_blank' href='https://www.google.com/maps/place/Layalina+Mediterranean+Restaurant+and+Lounge/@33.1981539,-97.1390667,15z/data=!4m6!3m5!1s0x864dca57a72623ef:0xe217202efbbec4f2!8m2!3d33.2147064!4d-97.1347732!16s%2Fg%2F11b7d_99ly?entry=ttu'>
                                    <FontAwesomeIcon style={{ fontSize: '18px' }} icon={faLocationDot} />
                                </a>
                            </li>
                            <li className='social-link'>
                                <a target='_blank' href='https://www.facebook.com/layalinamediterraneanrestaurant/'>
                                    <FontAwesomeIcon style={{ fontSize: '18px' }} icon={faFacebookF} />
                                </a>
                            </li>
                            <li className='social-link'>
                                <a target='_blank' href='https://www.yelp.com/biz/layalina-mediterranean-restaurant-and-lounge-denton-5'>
                                    <FontAwesomeIcon style={{ fontSize: '18px' }} icon={faYelp} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-col-2'>
                        <p>222 W. Hickory ST</p>
                        <p>Denton, TX 76201</p>
                        <p>MON-WED <span>(11AM - 10:30PM)</span></p>
                        <p>THUR - SAT <span>(11AM - 11:30PM)</span></p>
                        <p>SUN <span>(12PM - 9:30PM)</span></p>
                        <a href='mailto:layalinadenton14@gmail.com'>layalinadenton14@gmail.com</a>
                    </div>
                </div>
                <div className="layalina-copyright">
                    <p>© 2018 Layalina Mediterranean</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer