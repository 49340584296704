import React from 'react'
import './CustomerCard.css'
import { Review } from '../../types'

function CustomerCard(props: Review) {
    return (
        <div className='customer-card-wrapper'>
            <div className="customer-content">
                <img src={props.icon} width={80} alt='customer-image' />
                <p className="customer-text font-reg">
                    {props.description}
                </p>
                <div className="customer-info">
                    <span className='font-md' style={{ fontSize: '16px' }}>{props.customer} </span>
                    &nbsp;|&nbsp;
                    <a href={props.readMore} target='_blank' className='customer-cta'><span>Read More</span></a>
                </div>
            </div>
        </div>
    )
}

export default CustomerCard