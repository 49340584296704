import React from 'react';
import Logo from '../../assets/layalina-gold-logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Nav } from '../../types';
import './navbar.css';


function Navbar(props: Nav) {
  const routePath = useLocation().pathname;
  const navigate = useNavigate();

  const { sidebarOpen, handleSidebarOpen } = props

  return (
    <nav
      className={`navWrapper ${routePath === '/' ? '' : 'navWrapper-sticky'}`}
    >
      <div className='navContent'>
        <div
          className='logo-image'
          onClick={() => navigate('/')}
        >
          <img
            width='230'
            src={Logo}
            alt='logo'
          />
        </div>
        <ul className='navLinks'>
          <li>
            <a href='https://www.doordash.com/store/layalina-mediterranean-restaurant-denton-892410/'>
              Order Now
            </a>
          </li>
          <li>
            <a href='https://mylightspeed.app/VEWKEZNQ/C-ordering/menu'>
              Our Menu
            </a>
          </li>
          <li>
            <a href='/catering'>Catering</a>
          </li>
          <li>
            <a href='/reservations'>Reservations</a>
          </li>
          <li onClick={() => navigate('/gallery')}>
            <a href='/gallery'>Gallery</a>
          </li>
          <li onClick={() => navigate('/contact')}>
            <a href='/contact'>Contact</a>
          </li>
        </ul>
        <p className='nav-menu-icon'>
          <FontAwesomeIcon
            style={{ fontSize: '32px' }}
            icon={faBars}
            onClick={() => handleSidebarOpen(true)}
          />
        </p>
      </div>
    </nav>
  );
}

export default Navbar;
