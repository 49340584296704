import Slider from 'react-slick';
import MediaCard from '../../components/MediaCard/MediaCard';
import CustomerCard from '../../components/CustomerCard/CustomerCard';
import Map from '../../components/Map/Map';
import headImageOne from '../../assets/boquet.jpg';
import headImageTwo from '../../assets/buffettable.jpg';
import headImageThree from '../../assets/mixedplatter2.jpg';
import LayalinaHummusAward from '../../assets/layalina-hummus-award.jpg';
import LayalinaMention from '../../assets/layaline-mention-texas.jpg';
import layalinaReviews from '../../config/reviewsConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCutlery,
  faPhone,
  faBurger,
} from '@fortawesome/free-solid-svg-icons';
import './Home.css';

const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const homeSliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
};

type Props = {};

const Home = (props: Props) => {
  return (
    <div className='page-container'>
      <div className='home-bg-gd'></div>
      <div className='content-wrapper'>
        {/* header */}
        <div className='layalina-header'>
          <Slider
            className='head-slider'
            {...homeSliderSettings}
          >
            {[headImageOne, headImageTwo, headImageThree].map((image, i) => (
              <img
                key={`head-image-${i + 1}`}
                src={image}
                className={`head-image head-image-${i + 1}`}
              />
            ))}
          </Slider>
          <div className='header-content'>
            <div className='heading-wrapper'>
              <h1 className='heading-main line-1'>
                It's Not Just About Dining Out
              </h1>
              <h1 className='heading-main line-2'>
                It's About The Layalina Experience
              </h1>
            </div>
            <p className='header-text font-md'>
              Looking into 2024 and beyond we hope to continue to serve the
              Denton community with integrity and attention to detail.
            </p>
            <p className='main-cta'>
              <a
                className='header-cta'
                href='#layalina-about'
              >
                Read More
              </a>
            </p>
          </div>
        </div>

        {/* About Us  */}
        <section
          id='layalina-about'
          className='about-us-wrapper'
        >
          <div className='section-content'>
            <div className='inner-section'>
              <h2 className='section-heading'>About Layalina</h2>
              <p className='section-desc font-reg'>
                A family owned and operated business with decade long roots in
                the Denton Community, Layalina aims to incorporate authentic
                Mediterranean flavors with a spirit of friendship and fellowship
                through food.
              </p>
              <div className='about-us-cards'>
                <div className='card'>
                  <div className='card-cover'>
                    <img
                      src='http://s3-eu-west-1.amazonaws.com/com.ikentoo.prod.rich-content-store/56601_9d67f9bd-2b00-441d-9b35-0b1427b59d4f.image/png'
                      alt='card-1'
                      className='cover-image'
                    />
                  </div>
                </div>
                <div className='card'>
                  <div className='card-cover'>
                    <img
                      src='http://s3-eu-west-1.amazonaws.com/com.ikentoo.prod.rich-content-store/56601_bf2498c7-0905-4630-a03b-cef293ab3974.image/png'
                      alt='card-2'
                      className='cover-image'
                    />
                  </div>
                </div>
                <div className='card'>
                  <div className='card-cover'>
                    <img
                      src='http://s3-eu-west-1.amazonaws.com/com.ikentoo.prod.rich-content-store/56601_f9da2e5a-41cf-4a68-9afb-68ea58572108.image/png'
                      alt='card-3'
                      className='cover-image'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Media */}
        {/* <section className="layalina-media">
                    <div className="section-content">
                        <div className="inner-section">
                            <h2 className="media-heading">
                                Layalina In The Media
                            </h2>
                            <div className="media-slider-wrapper">
                                <MediaCard />
                            </div>
                        </div>
                    </div>
                </section> */}
        {/* Awards */}
        <section
          className='layalina-awards'
          style={{ display: 'none' }}
        >
          <div className='section-content'>
            <div className='inner-section'>
              <h2 className='section-heading'>Awards & Mentions</h2>
              <div className='awards-wrapper'>
                <div className='award-box'>
                  <img
                    height={350}
                    src={LayalinaHummusAward}
                  />
                  <h4>Broken Lens Film Festival.</h4>
                  <p>2022</p>
                </div>
                <div className='award-box'>
                  <img
                    height={350}
                    src={LayalinaMention}
                  />
                  <h4>My Curly Adventures.</h4>
                  <p>2023</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <section className='testimonials'>
          <div className='section-content'>
            <div className='inner-section'>
              <h2 className='section-heading'>What Our Customer Say</h2>
              <Slider
                className='review-slider'
                {...sliderSettings}
              >
                {layalinaReviews.map((review, i) => (
                  <CustomerCard
                    key={`Customer${i}`}
                    {...review}
                  />
                ))}
              </Slider>
              {/* <CustomerCard {...layalinaReviews[3]} /> */}
            </div>
          </div>
        </section>

        <section className='banner-wrapper'>
          <div className='banner-box banner-box-1'>
            <div className='banner-icon center'>
              <FontAwesomeIcon
                style={{ fontSize: '28px' }}
                icon={faPhone}
              />
            </div>
            <div className='banner-text'>Call Us: (940)382-3663</div>
          </div>
          <div className='banner-box banner-box-2'>
            <div className='banner-icon center'>
              <FontAwesomeIcon
                style={{ fontSize: '28px' }}
                icon={faBurger}
              />
            </div>
            <div className='banner-text'>Catering</div>
          </div>
          <div className='banner-box banner-box-3'>
            <div className='banner-icon center'>
              <FontAwesomeIcon
                style={{ fontSize: '28px' }}
                icon={faCutlery}
              />
            </div>
            <div className='banner-text'>Menu</div>
          </div>
        </section>

        <Map />
      </div>
    </div>
  );
};

export default Home;
