type Props = {};

function Map({}: Props) {
  return (
    <section className='location-map'>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13354.431909505276!2d-97.1390667!3d33.1981539!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864dca57a72623ef%3A0xe217202efbbec4f2!2sLayalina%20Mediterranean%20Restaurant%20and%20Lounge!5e0!3m2!1sen!2s!4v1710592870996!5m2!1sen!2s'
        width='100%'
        height='350'
        style={{ border: 0 }}
        allowFullScreen
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
    </section>
  );
}

export default Map;
