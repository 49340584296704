import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Gallery from './pages/Gallery/Gallery';
import Catering from './pages/Catering/Catering';
import Reservations from './pages/Reservations/Reservatios';
import Footer from './components/Footer/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import { useState } from 'react';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState<Boolean>(false);

  const handleSetSidebarOpen = (open: Boolean) => {
    setSidebarOpen(open);
  };

  return (
    <div className='app'>
      <BrowserRouter>
        <Sidebar
          sidebarOpen={sidebarOpen}
          handleSidebarOpen={handleSetSidebarOpen}
        />
        <NavBar
          sidebarOpen={sidebarOpen}
          handleSidebarOpen={handleSetSidebarOpen}
        />
        <Routes>
          <Route
            path='/'
            key='home'
            element={<Home />}
          />
          <Route
            path='/gallery'
            element={<Gallery />}
          />
          <Route
            path='/contact'
            element={<Contact />}
          />
          <Route
            path='/catering'
            element={<Catering />}
          />
          <Route
            path='/reservations'
            element={<Reservations />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
