import image1 from '../assets/gallery/all-platters.png'
import image2 from '../assets/gallery/baklavas.jpg'
import image3 from '../assets/gallery/baklavas2.jpg'
import image4 from '../assets/gallery/bluebulbs.jpg'
import image5 from '../assets/gallery/boquet.jpg'
import image6 from '../assets/gallery/buffet.jpg'
import image7 from '../assets/gallery/buffettable.jpg'
import image8 from '../assets/gallery/buffettable2.jpg'
import image9 from '../assets/gallery/gyro.jpg'
import image10 from '../assets/gallery/hummus.jpg'
import image11 from '../assets/gallery/hummus2.jpg'
import image12 from '../assets/gallery/interior2.jpg'
import image13 from '../assets/gallery/kettle.jpg'
import image14 from '../assets/gallery/kibbas.jpg'
import image15 from '../assets/gallery/kunafas.jpeg'
import image16 from '../assets/gallery/lantern.jpg'
import image17 from '../assets/gallery/mixedplatter.jpg'
import image18 from '../assets/gallery/mixedplatter2.jpg'
import image19 from '../assets/gallery/multipletrays.jpg'
import image20 from '../assets/gallery/my curly adventures.jpg'
import image21 from '../assets/gallery/philly.jpg'
import image22 from '../assets/gallery/pita.jpg'
import image24 from '../assets/gallery/satisfiedcustomers.jpg'
import image25 from '../assets/gallery/shawarma.jpg'
import image26 from '../assets/gallery/shawarma2.jpg'
import image27 from '../assets/gallery/shishtawook.jpg'
import image28 from '../assets/gallery/spicyhummus.jpg'
import image29 from '../assets/gallery/tilted.jpeg'
import image30 from '../assets/gallery/wings.jpg'
import { GalleryItem } from '../types'

export const images: GalleryItem[] = [
    {
        id: 1,
        src: image1,
        alt: 'Image 1',
        width: 400,
        height: 300,
    },
    {
        id: 2,
        src: image2,
        alt: 'Image 2',
        width: 500,
        height: 300,
    },
    {
        id: 3,
        src: image3,
        alt: 'Image3',
        width: 350,
        height: 500,
    },
    {
        id: 4,
        src: image4,
        alt: 'Image 4',
        width: 300,
        height: 300,
    },
    {
        id: 5,
        src: image5,
        alt: 'Image 5',
        width: 350,
        height: 300,
    },
    {
        id: 6,
        src: image6,
        alt: 'Image 6',
        width: 400,
        height: 500,
    },
    {
        id: 7,
        src: image7,
        alt: 'Image 7',
        width: 400,
        height: 300,
    },
    {
        id: 8,
        src: image8,
        alt: 'Image 8',
        width: 400,
        height: 300,
    },
    {
        id: 9,
        src: image9,
        alt: 'Image 9',
        width: 400,
        height: 500,
    },
    {
        id: 10,
        src: image10,
        alt: 'Image 10',
        width: 400,
        height: 500,
    },
    {
        id: 11,
        src: image11,
        alt: 'Image 11',
        width: 400,
        height: 300,
    },
    {
        id: 12,
        src: image12,
        alt: 'Image 12',
        width: 500,
        height: 300,
    },
    {
        id: 13,
        src: image13,
        alt: 'Image 13',
        width: 350,
        height: 500,
    },
    {
        id: 14,
        src: image14,
        alt: 'Image 14',
        width: 300,
        height: 300,
    },
    {
        id: 15,
        src: image15,
        alt: 'Image 15',
        width: 350,
        height: 300,
    },
    // {
    //     id:16,
    //     src: image16,
    //     alt: 'Image 16',
    //     width: 400,
    //     height: 500,
    // },
    {
        id: 17,
        src: image17,
        alt: 'Image 17',
        width: 400,
        height: 300,
    },
    {
        id: 18,
        src: image18,
        alt: 'Image 18',
        width: 400,
        height: 300,
    },
    {
        id: 19,
        src: image19,
        alt: 'Image 19',
        width: 400,
        height: 500,
    },
    // {
    //     id: 20,
    //     src: image20,
    //     alt: 'Image 20',
    //     width: 400,
    //     height: 500,
    // },
    {
        id: 21,
        src: image21,
        alt: 'Image 21',
        width: 400,
        height: 300,
    },
    {
        id: 22,
        src: image22,
        alt: 'Image 22',
        width: 500,
        height: 300,
    },
    {
        id: 24,
        src: image24,
        alt: 'Image 24',
        width: 300,
        height: 300,
    },
    {
        id: 25,
        src: image25,
        alt: 'Image 25',
        width: 350,
        height: 300,
    },
    {
        id: 26,
        src: image26,
        alt: 'Image 26',
        width: 400,
        height: 500,
    },
    // {
    //     id: 27,
    //     src: image27,
    //     alt: 'Image 27',
    //     width: 400,
    //     height: 300,
    // },
    // {
    //     id:28,
    //     src: image28,
    //     alt: 'Image 28',
    //     width: 400,
    //     height: 300,
    // },
    // {
    //     id: 29,
    //     src: image29,
    //     alt: 'Image 29',
    //     width: 400,
    //     height: 500,
    // },
    // {
    //     id: 30,
    //     src: image30,
    //     alt: 'Image 30',
    //     width: 400,
    //     height: 500,
    // },
];