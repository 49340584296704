import DoorDash from '../assets/doordash.png'
import Facebook from '../assets/facebook.png'
import UberEats from '../assets/ubereats.png'
import Google from '../assets/google.png'
import { Review } from '../types'

const layalinaReviews: Review[] = [
    {
        icon: UberEats,
        description: "It's just so freaking good, and it's hard to find good Mediterranean/middle eastern food outside of Dallas.",
        customer: 'Uber Eats',
        readMore: 'https://www.ubereats.com/store/layalina-mediterranean-restaurant/2VUeTZp6VCSa4uWqbetmRw?diningMode=DELIVERY&ps=1'
    },
    {
        icon: Facebook,
        description: 'Great food with a  nice setting. Coming back soon. Romantic atmosphere · Late-night menu · Stylish interior.',
        customer: 'Facebook',
        readMore: 'https://www.facebook.com/layalinamediterraneanrestaurant/reviews'
    },
    {
        icon: DoorDash,
        description: "This place is fantastic! I've tried most items on the menu - all consistently wonderful, dining-in or delivered. Even simple items like hummus, kifta, and the salads stand apart in execution. Best part is it's all fresh and a healthier choice in cuisine!",
        customer: 'Door Dash',
        readMore: 'https://www.doordash.com/reviews/store/892410/'
    },
    {
        icon: Google,
        description: "This was mine and my wife's first time here and it exceeded our expectations. The atmosphere of the restaurant is fantastic, giving you a true feeling of being in another country (the music helped set the mood as well). We ordered lentil soup, fried cauliflower, falafel sandwich, and the Arabian salad. All entrees were served hot, tasted fresh, and the seasoning was quite complementary. Great option if you are a vegetarian, although they have plenty of meat options as well. We will definitely be back for another visit!",
        customer: 'Google',
        readMore: 'https://g.co/kgs/SZBXMM2'
    }
]

export default layalinaReviews