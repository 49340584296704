import React, { useState } from 'react';
import Map from '../../components/Map/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';

type Props = {};
type FormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

function Contact({}: Props) {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    const data = {
      service_id: 'service_la93xnn',
      template_id: 'template_mk96a9p',
      user_id: 'IaB2FcDKmicVk4QV4',
      template_params: {
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        from_message: formData.message,
        to_name: 'Layalina',
      },
    };

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => console.log('email:', data));

    // const content = await response.json();
    // console.log('email sent: ',content);
  };

  return (
    <div className='contact-page-wrapper'>
      <section className='contact-banner'>
        <h2>Contact Us</h2>
      </section>
      <section className='contact-us'>
        <div className='contact-row'>
          <div className='contact-details-column'>
            {/* <h5>Contact Us</h5> */}
            <h2>We'd love to hear from you</h2>
            <p>Send us a message and we'll be in touch soon</p>
            <div className='contact-details'>
              <div className='contact-card'>
                <h6>
                  <FontAwesomeIcon
                    style={{ fontSize: '18px' }}
                    icon={faLocationDot}
                  />{' '}
                  Location :
                </h6>
                <p>222 W. Hickory ST</p>
                <p>Denton, TX 76201</p>
              </div>
              <div className='contact-card'>
                <h6>
                  <FontAwesomeIcon
                    style={{ fontSize: '18px' }}
                    icon={faPhone}
                  />
                  Phone :
                </h6>
                <p>(940)382-3663</p>
              </div>
            </div>
          </div>
          <div className='contact-form-column'>
            <div className='form-wrapper'>
              <form
                className='contact-form'
                onSubmit={handleSubmit}
              >
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  placeholder='Name (Required)'
                  onChange={handleChange}
                  required
                />

                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  placeholder='Email (Required)'
                  onChange={handleChange}
                  required
                />

                <input
                  type='tel'
                  name='phone'
                  value={formData.phone}
                  placeholder='Phone (000-000-0000)'
                  onChange={handleChange}
                />

                <textarea
                  name='message'
                  value={formData.message}
                  placeholder='Message (Required)'
                  onChange={handleChange}
                  rows={4}
                  cols={50}
                  required
                />

                <button
                  type='submit'
                  className='contact-submit-button'
                >
                  Contact Us
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='map-wrapper'>
        <Map />
      </section>
    </div>
  );
}

export default Contact;
