import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faqsQues } from '../../config/faqsConfig';
import ServicesImage from '../../assets/gallery/kettle.jpg';
import Grid1 from '../../assets/gallery/combo.jpg';
import Grid2 from '../../assets/gallery/grapeleaves.jpg';
import Grid3 from '../../assets/gallery/wings.jpg';
import Grid4 from '../../assets/gallery/pita.jpg';
import ContactBannerCta from '../../components/ContactBannerCta/ContactBannerCta';
import './Reservations.css';

type Props = {};

function Reservations({}: Props) {
  return (
    <div className='reservation-page-wrapper'>
      <section className='reservation-banner'>
        <h2>Reservations</h2>
      </section>

      <section className='services-wrapper resv-container'>
        <div className='reservation-heading'>
          <h3>What We Offer</h3>
        </div>
        <div className='reservation-services-row'>
          <div className='services-image-col'>
            <img
              src={ServicesImage}
              alt=''
            />
          </div>
          <div className='services-description-col'>
            <h4 className='list-heading'>For your special day</h4>
            <ul className='services-list'>
              <li className='services-list-item'>
                <div className='service-description'>
                  <h6>Community and Family Events</h6>
                  <p>
                    Have a community picnic or family gathering? What about a
                    birthday or faith based celebration? Our freshly prepared,
                    healthy and delicious menu will add to the spirit of sharing
                    and togetherness.
                  </p>
                </div>
              </li>
              <li className='services-list-item'>
                <div className='service-description'>
                  <h6>Corporate Events</h6>
                  <p>
                    Our timely and professional catering services are ideal for
                    your professional catering needs. From in office team
                    meetings and work lunches to holiday parties, we provide
                    delivery to your specified location, packaging according to
                    your requirements and assistance in setup.
                  </p>
                </div>
              </li>
              <li className='services-list-item'>
                <div className='service-description'>
                  <h6>Private Event Rental</h6>
                  <p>
                    Ideal for small to medium sized events, our indoor dining
                    space and lounge area can be customized to your seating
                    needs. With a capacity of 150, we offer a warm and welcoming
                    ambiance, attentive service and a fresh, flavorful menu.
                  </p>
                </div>
              </li>
              <li className='services-list-item'>
                <div className='service-description'>
                  <h6>Other Events</h6>
                  <p>
                    Birthdays, Graduations, Family Reunions or any other
                    Celebration? Let us work with you to create beautiful
                    memories and make your next hosting experience a memorable
                    one.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className='faqs-wrapper resv-container'>
        <div className='faqs-row'>
          <div className='faqs-description-col'>
            <h3>Additional Info</h3>
            <p>
              Looking for a space to host your next event? Look no further than
              our location in the heart of Downton Denton, right off the square.
            </p>
            <div className='questions-wrapper'>
              <h6>Contact us to get your queries answered</h6>
              <ul className='faqs-list'>
                {faqsQues.map((ques, i) => (
                  <li key={`ques-${i}`}>
                    <FontAwesomeIcon
                      style={{ fontSize: '18px', marginRight: '10px' }}
                      icon={faAngleRight}
                    />
                    {ques}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='grid-images-col'>
            <div className='image-grid grid-row-1'>
              <img
                className='grid-image'
                src={Grid1}
                alt=''
              />
              <img
                className='grid-image'
                src={Grid2}
                alt=''
              />
            </div>
            <div className='image-grid grid-row-2'>
              <img
                className='grid-image'
                src={Grid3}
                alt=''
              />
              <img
                className='grid-image'
                src={Grid4}
                alt=''
              />
            </div>
          </div>
        </div>
      </section>

      <ContactBannerCta />
    </div>
  );
}

export default Reservations;